.Main_warpper {
   height: 80vh;
   padding: 25px 50px !important;
}

.Music_list {
   display: flex;
   flex-direction: row;
   gap: 2rem 4rem;
   flex-wrap: wrap;
   cursor: pointer;
   list-style: none;
}

.List_Image {
   width: 200px !important;
   height: 200px !important;
   border-radius: 20px !important;
   object-fit: cover;
}

.List_name {
   padding: 10px;
   font-size: 20px;
   font-weight: lighter;
   color: #fff;
   text-align: center;
   letter-spacing: 1px;
}

.Music_list2 {
   display: flex;
   flex-direction: row;
   gap: 2rem;
   flex-wrap: wrap;
}

.Music_list2>li {
   width: 300px !important;
   height: 50px;
   padding: 10px 10px;
   list-style: none;
   color: #fff;
   font-size: 20px !important;
   background: rgba(255, 255, 255, 0.2);
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);
   display: flex;
   align-items: center;
   justify-content: space-around;
   border-radius: 10px;
}

.Music_list2>li>svg {
   color: red !important;
   font-size: 40px !important;
}

.Active_song {
   border: 1px solid red !important;
}


@media screen and (max-width: 768px) {

   .Main_warpper {
      padding: 10px 10px !important;
      height: 84vh !important;
      overflow: scroll;
   }

   .Music_list {
      height: 100%;
      gap: 0.3rem 4rem;
      justify-content: center;
      padding: 0px !important;
   }

   .List_Image {
      width: 100px !important;
      height: 100px !important;
      border-radius: 10px !important;
      object-fit: cover;
   }

   .List_name {
      padding: 10px;
      font-size: 10px;
   }

   .Music_list2 {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: flex-start;
      align-items: center;
      padding: 0% !important;
      flex-wrap: nowrap;
      overflow: scroll;
      padding-top: 20px !important;
   }

   .Music_list2>li {
      width: 350px !important;
      height: 50px;
      text-align: left !important;
      font-size: 20px !important;
      border-radius: 10px;
      justify-content: flex-start !important;
      letter-spacing: 3px !important;
   }

   .Music_list2>li:hover {
      color: red !important;
   }
}