.PlayerContent {
   height: 10vh !important;
   background: rgba(255, 255, 255, 0.2);
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);
}

.Player_Warper {
   height: 100% !important;
   display: flex;
   justify-content: space-around;
   align-items: center;
}

.Player_Warper>p {
   font-size: 20px;
   color: #fff;
   align-self: flex-end;
   text-transform: capitalize;
}

.Player_Warper>svg {
   font-size: 45px !important;
   color: red !important;
}

@media screen and (max-width: 768px) {
   .PlayerContent {
      height: 8vh !important;
      flex-wrap: wrap;
   }

   .Player_Warper>p {
      font-size: 20px;
      color: #fff;
      display: flex;
      align-self: center;
      margin-bottom: 0px !important;
   }

   .Player_Warper>svg {
      font-size: 25px !important;
      color: red !important;
   }
}