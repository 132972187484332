@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100;400;700&display=swap');

body,
html {
  font-family: 'Catamaran', sans-serif;
}

body {
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
}

* {
  margin: 0%;
  padding: 0%;
}