#NavBar {
   position: sticky !important;
   top: 0px !important;
   height: 10vh !important;
}


.minNav {
   display: flex;
   justify-content: space-between;
   width: 100%;
   background: rgba(255, 255, 255, 0.2);
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(5px);
   -webkit-backdrop-filter: blur(5px);
   color: #fff;
   padding: 10px 60px;
}

.Logo {
   font-size: 30px;
   letter-spacing: 3px;
   font-weight: bold;
}

.second_logo {
   font-size: 25px;
   color: red;
}

.Logo>svg {
   color: red;
   font-size: 50px !important;
   margin-right: 10px;
}

.back_btn {
   padding: 10px 20px;
   color: red;
   cursor: pointer;
   font-size: 20px;
}

.back_btn>svg {
   font-size: 25px;
}

@media screen and (max-width: 768px) {
   #NavBar {
      height: 8vh !important;
   }

   .minNav {
      padding: 10px 10px;
      justify-content: center;
   }

   .Logo {
      font-size: 15px;
      letter-spacing: 3px;
      font-weight: bold;
   }

   .second_logo {
      font-size: 15px;
      color: red;
   }

   .Logo>svg {
      color: red;
      font-size: 30px !important;
   }

   .back_btn {
      color: red;
      cursor: pointer;
      font-size: 15px;
      position: relative;
      top: -10px;
      left: 50px;
   }

   .back_btn>svg {
      font-size: 15px;
   }
}